@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  height: 100%;
}

.rs-picker-menu {
  z-index: 100;
}

.react-select__input:focus,
.react-select__input:active {
  outline: none;
  border-color: none;
  box-shadow: none;
}
